import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';

const Templates = () => {
  const context = useContext(Context.Context);
  let [templates] = useState('');
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const authUser = context.authenticatedUser;

  let navigate = useNavigate();

  useEffect(() => {
    context.data
      .getTemplates(authUser.emailAddress, authUser.password)
      .then(response => {
        setData(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing data', error);
        navigate('/error');
      })
      .finally(() => setIsLoading(false));
  }, [navigate, context.data, authUser.emailAddress, authUser.password]);

  if (data.length) {
    templates = data.map(template => {
      return (
        <Link
          to={`/templates/${template.id}`}
          className="course--module course--link"
          key={template.id}
        >
          <h2 className="course--label">{template.name}</h2>
        </Link>
      );
    });
  }

  return isLoading ? (
    <Loading />
  ) : (
    <div className="wrap main--grid">
      <Link to="/templates/create" className="course--module course--add--module">
        <span className="course--add--title">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 13 13"
            className="add"
          >
            <polygon points="7,6 7,0 6,0 6,6 0,6 0,7 6,7 6,13 7,13 7,7 13,7 13,6 "></polygon>
          </svg>
          Novo Template
        </span>
      </Link>
      {templates}
    </div>
  );
};

export default Templates;
