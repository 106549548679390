import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../Context';

const CreateTemplate = () => {
  const context = useContext(Context.Context);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState([]);
  const authUser = context.authenticatedUser;

  let navigate = useNavigate();

  const onChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'name') {
      setName(value);
    }
  };

  const submit = event => {
    event.preventDefault();
    // Template object to create a template
    const template = {
      name: name,
      userId: authUser.id
    };

    context.data
      .createTemplate(template, authUser.emailAddress, authUser.password)
      .then(errors => {
        if (errors.length) {
          setErrors(errors);
        } else {
          navigate('/templates');
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };

  const cancel = event => {
    event.preventDefault();
    navigate('/');
  };

  return (
    <div className="wrap">
      <h2>Template</h2>
      {errors.length ? (
        <div className="validation--errors">
          <h3>Validation Errors</h3>
          <ul>
            {errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      ) : null}
      <form>
        <div className="main--flex">
          <div>
            <label htmlFor="name">Nome do Template</label>
            <input id="name" name="name" type="text" value={name} onChange={onChange} />
          </div>
        </div>
        <button className="button" type="submit" onClick={submit}>
          Criar Template
        </button>
        <button className="button button-secondary" onClick={cancel}>
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default CreateTemplate;
