import React from 'react';
import { ReactComponent as IconLight } from './icon-light.svg';

const Loading = () => (
  <div className="loading">
    <h2>
      <IconLight style={{ width: '34px', height: '34px', marginRight: '10px' }} /> Carregando...
    </h2>
  </div>
);

export default Loading;
