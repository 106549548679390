import React, { useState, useEffect } from 'react';

const Popup = ({ message, onClose, isLoading }) => {
  const popupContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

  const popupStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '150px'
  };

  const messageStyle = {
    marginBottom: '20px'
  };

  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #370144',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    margin: '0 auto'
  };

  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes =
      '@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }';
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  return (
    <div style={popupContainerStyle}>
      <div style={popupStyle}>
        <span style={messageStyle}>{message}</span>
        {isLoading ? (
          <div style={spinnerStyle}></div>
        ) : (
          <button
            onClick={onClose}
            style={{
              padding: '8px 16px',
              backgroundColor: '#370144',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Fechar
          </button>
        )}
      </div>
    </div>
  );
};

export default Popup;
