import React, { useCallback, useContext, useState } from 'react';
import { Box, Card, CardContent, Grid2, Typography } from '@mui/material';
import WebhookIcon from '../../assets/webhooks.svg';
import HubspotIcon from '../../assets/hubspot.svg';
import RdIcon from '../../assets/rdstation.svg';
import IntegrationModal from './IntegrationModal';
import Context from '../../Context';
import { message } from 'antd';

function IntegrationPage() {
  const context = useContext(Context.Context);
  const authUser = context.authenticatedUser;
  const [messageApi] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState({});
  const [webHook, setWebHook] = useState(decodeURIComponent(authUser?.webHook));
  const [apikeyHubspot, setApiKeyHubspot] = useState(authUser?.apikeyHubspot);
  const [apikeyRdStation, setApikeyRdStation] = useState(authUser?.apikeyRdStation);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = integration => {
    setSelectedIntegration(integration);
    setOpen(true);
  };

  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
  };

  const handleConnectClick = () => {
    setIsLoading(true);
    authUser.webHook = webHook;
    authUser.apikeyHubspot = apikeyHubspot;
    authUser.apikeyRdStation = apikeyRdStation;
    context.data
      .setWebHook(
        encodeURIComponent(webHook),
        authUser.emailAddress,
        authUser.password,
        apikeyHubspot,
        apikeyRdStation
      )
      .then(errors => {
        if (errors.length) {
          messageApi.open({
            type: 'error',
            content: errors.join(', ')
          });
        } else {
          messageApi.open({
            type: 'success',
            content: 'Integração CRM Atualizada!'
          });
          handleClose();
        }
      })
      .catch(error => {
        console.error(error);
        messageApi.open({
          type: 'error',
          content: 'Ocorreu um erro ao tentar salvar a integração.'
        });
      });
  };

  const integrations = [
    {
      id: 1,
      name: 'Webhook',
      description: 'Sincronizar eventos customizados via Webhook.',
      icon: WebhookIcon,
      currentValue: decodeURIComponent(webHook),
      setValue: setWebHook
    },
    {
      id: 2,
      name: 'Hubspot',
      description: 'Sincronizar resumos e destaques de reuniões com o HubSpot.',
      icon: HubspotIcon,
      currentValue: apikeyHubspot,
      setValue: setApiKeyHubspot
    },
    {
      id: 3,
      name: 'RD Station',
      description: 'Sincronizar  resumos e destaques de reuniões com o RD Station.',
      icon: RdIcon,
      currentValue: apikeyRdStation,
      setValue: setApikeyRdStation
    }
  ];

  const renderIntegration = useCallback(() => {
    const integration = integrations.find(integration => integration.id === selectedIntegration.id);
    return integrations.map(integration => (
      <Grid2 item key={integration.id}>
        <Card
          sx={{ width: 150, height: 130, textAlign: 'center', cursor: 'pointer' }}
          elevation={5}
          onClick={() => handleClickOpen(integration)}
        >
          <CardContent>
            <img
              src={integration.icon}
              alt={`${integration.name} Icon`}
              style={{ width: '50px', height: '50px' }}
            />
            <Typography variant="subtitle2" mt={2}>
              {integration.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid2>
    ));
  }, [apikeyHubspot, apikeyRdStation, webHook, selectedIntegration, integrations]);

  return (
    <div className="wrap">
      <Box mt={3}>
        <Grid2 container spacing={3}>
          {renderIntegration()}
        </Grid2>

        <IntegrationModal
          open={open}
          handleClose={handleClose}
          title={selectedIntegration.name}
          description={selectedIntegration.description}
          icon={selectedIntegration.icon}
          initialValue={selectedIntegration.currentValue}
          setValue={selectedIntegration.setValue}
          handleConnectClick={handleConnectClick}
          isLoading={isLoading}
        />
      </Box>
    </div>
  );
}

export default IntegrationPage;
