import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputText from '../common/InputText';
import CustomLoadingButton from '../common/CustomLoadingButton';

const IntegrationModal = ({
  open,
  handleClose,
  title,
  description,
  icon,
  initialValue,
  setValue,
  handleConnectClick,
  isLoading
}) => {
  const [inputValue, setInputValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setInputValue(initialValue || '');
  }, [initialValue]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = event => {
    setInputValue(event.target.value);
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
      PaperProps={{
        sx: {
          width: '700px',
          maxHeight: '80vh'
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="flex-start" mb={2}>
          <img
            src={icon}
            alt={`${title} Icon`}
            style={{ width: '70px', height: '70px', marginRight: '12px' }}
          />
          <Box>
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="subtitle2" color="#666">
              {description}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1" mb={1}>
          {title === 'Webhook' ? 'Endpoint do Webhook' : `Chave de API do ${title}`}
        </Typography>
        <Typography variant="subtitle2" color="#666" mb={2}>
          {title === 'Webhook'
            ? `Insira o endpoint do ${title} para permitir a sincronização de eventos via Webhook.`
            : `Insira sua chave de API do ${title} para permitir a integração e sincronização de dados com a plataforma.`}
        </Typography>

        <InputText
          label={title === 'Webhook' ? 'Endpoint' : 'Chave de API'}
          variant="outlined"
          fullWidth
          margin="normal"
          value={inputValue}
          onChange={handleChange}
          size="small"
          type={title === 'Webhook' ? 'text' : showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: title !== 'Webhook' && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </DialogContent>

      <DialogActions sx={{ padding: '16px' }}>
        <CustomLoadingButton
          onClick={handleConnectClick}
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: '#ff4081',
            '&:hover': {
              backgroundColor: '#e73370'
            },
            textTransform: 'none',
            padding: '10px 24px',
            fontSize: '16px',
            width: '150px',
            height: '45px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          loading={isLoading}
          loadingPosition="center"
          loadingIndicator={<CircularProgress color="secondary" size={24} />}
        >
          Salvar
        </CustomLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default IntegrationModal;
