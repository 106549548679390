import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)(
  ({ inputFontSize, labelFontSize, helperTextFontSize }) => ({
    '& .MuiOutlinedInput-root': {
      '& input': {
        fontSize: inputFontSize || '14px'
      }
    },
    '& .MuiInputLabel-root': {
      fontSize: labelFontSize || '14px'
    },
    '& .MuiFormHelperText-root': {
      fontSize: helperTextFontSize || '12px'
    }
  })
);

const InputText = props => {
  return <StyledTextField {...props} />;
};

export default InputText;
