import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Data from './Data';

const Context = React.createContext();

export class Provider extends Component {
  state = {
    authenticatedUser: Cookies.getJSON('authenticatedUser') || null,
    templateId: null
  };

  constructor() {
    super();
    this.data = new Data();
  }
  async componentDidMount() {
    const authenticatedUser = Cookies.getJSON('authenticatedUser') || null;
    if (authenticatedUser !== null && authenticatedUser.id) {
      try {
        const password = authenticatedUser.password;
        const user = await this.data.getUser(
          authenticatedUser.emailAddress,
          authenticatedUser.password
        );
        if (user !== null && user.id) {
          this.setState(
            () => ({
              authenticatedUser: { ...user, ...{ password } }
            }),
            () => {
              Cookies.set('authenticatedUser', JSON.stringify(this.state.authenticatedUser), {
                expires: 1
              });
            }
          );
        }
      } catch (error) {
        console.error('Erro ao carregar o usuário:', error);
      }
    }
  }

  render() {
    const { authenticatedUser, templateId } = this.state;
    const value = {
      authenticatedUser,
      templateId,
      data: this.data,
      actions: {
        signIn: this.signIn,
        signOut: this.signOut,
        setTemplateId: this.setTemplateId
      }
    };
    return <Context.Provider value={value}>{this.props.children}</Context.Provider>;
  }

  /**
   * Signs the user in by retrieving the user's details, setting the authenticatedUser state and browser cookies
   * @param {String} emailAddress
   * @param {String} password
   * @returns {Object} user
   */
  signIn = async (emailAddress, password) => {
    const user = await this.data.getUser(emailAddress, password);
    if (user !== null && user.id) {
      this.setState(
        () => ({
          authenticatedUser: { ...user, ...{ password } }
        }),
        () => {
          Cookies.set('authenticatedUser', JSON.stringify(this.state.authenticatedUser), {
            expires: 1
          });
        }
      );
    }
    return user;
  };

  /**
   * Signs the user out by setting a null authenticated user and removing cookies
   */
  signOut = () => {
    this.setState({ authenticatedUser: null });
    Cookies.remove('authenticatedUser');
  };

  /**
   * Define o ID do modelo no estado do contexto
   * @param {string} templateId
   */
  setTemplateId = templateId => {
    this.setState({ templateId });
  };
}

export const Consumer = Context.Consumer;

/**
 * A higher-order component that wraps the provided component in a Context Consumer component.
 * @param {class} Component - A React component.
 * @returns {function} A higher-order component.
 */
export function withContext(Component) {
  return function ContextComponent(props) {
    return (
      <Context.Consumer>{context => <Component {...props} context={context} />}</Context.Consumer>
    );
  };
}

const contextObjects = { withContext, Context };
export default contextObjects;
