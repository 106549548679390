import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';

const AuthCalendarOutlook = () => {
  const context = useContext(Context.Context);
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const authUser = context.authenticatedUser;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const returnedState = queryParams.get('state');
  let navigate = useNavigate();
  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await context.data.getCalendarOutlook(
          authUser.emailAddress,
          authUser.password,
          code,
          returnedState
        );
        setData(response);
        navigate('/meetings/synccalendaroutlook');
      } catch (error) {
        console.error('Error fetching and parsing data', error);
        navigate('/error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCalendarData();
  }, [navigate, context.data, authUser.emailAddress, authUser.password, code, returnedState]);

  return isLoading ? <Loading /> : <div>{data}</div>;
};

export default AuthCalendarOutlook;
