import React, { useContext, useEffect, useState } from 'react';
import Context from '../Context';
import { Link, useNavigate } from 'react-router-dom';
import logo from './full-light-horiz.png';
import io from 'socket.io-client';
import config from '../config';
import { Modal } from 'antd';

const url = config.apiBaseUrl;
const socket = io(url.substring(0, url.indexOf('/api')), {
  path: '/api/socket-io',
  transports: ['websocket'] // Configura o cliente Socket.IO para usar apenas o transporte WebSocket
});

const Header = () => {
  const context = useContext(Context.Context);
  const authUser = context.authenticatedUser;
  const [modalAberto, setModalAberto] = useState(false);
  let navigate = useNavigate();

  const handleModalClose = () => {
    setModalAberto(false);
  };

  const handleModalOpen = () => {
    setModalAberto(true);
  };

  useEffect(() => {
    // Adiciona os listeners de eventos após a montagem do componente
    socket.on('connect', () => {
      console.log('Conectado ao servidor Socket.IO');
    });

    socket.on('usuario-atualizado', mensagem => {
      console.log('Recebido do servidor:', mensagem);
    });

    // Limpa os listeners de eventos ao desmontar o componente
    return () => {
      socket.off('connect');
      socket.off('usuario-atualizado');
    };
  }, []);

  return (
    <header>
      <div className="wrap header--flex">
        <h1 className="header--logo">
          <Link to="/">
            <img
              src={logo}
              alt="SalesBud"
              style={{ width: '200px', height: 'auto', marginRight: '10px' }}
            />
          </Link>
        </h1>
        {authUser ? (
          <div className="links-container">
            <Link to="/" className="course--module">
              <span className="course--add--title">Reuniões</span>
            </Link>
            <Link to="/templates" className="course--module">
              <span className="course--add--title">Templates</span>
            </Link>
            {authUser.isAdmin ? (
              <Link to="/meetings/enablement" className="course--module">
                <span className="course--add--title">Enablement</span>
              </Link>
            ) : null}
            <Link to="/integrating" className="course--module">
              <span className="course--add--title">Integrações</span>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
        <nav>
          {authUser ? (
            <ul className="header--signedin">
              <li>
                {authUser.firstName} {authUser.lastName}{' '}
                {authUser.company ? `[${authUser.company.name}] tem ` : ' Voce tem '}{' '}
                {authUser.company
                  ? authUser.company.planMeeting - authUser.company.countMeeting
                  : 100 - authUser.countMeeting}{' '}
                reuniões
              </li>{' '}
              <button onClick={handleModalOpen}>Faça um Tour</button>
              <li>
                <Link to="/signout">Logout</Link>
              </li>
            </ul>
          ) : (
            <ul className="header--signedout">
              <li>
                <Link to="/signin">Login</Link>
              </li>
              <li>
                <Link to="/signup">Criar usuário</Link>
              </li>
            </ul>
          )}
        </nav>
      </div>
      <hr className="divider" style={{ border: 'none', height: '1px', backgroundColor: '#ccc' }} />
      <Modal
        title="Faça um TOUR"
        visible={modalAberto}
        onCancel={handleModalClose}
        footer={null}
        width="95%"
        style={{ top: 5 }}
        bodyStyle={{ height: '530px' }}
        centered={true}
      >
        <iframe
          title="Tour"
          src="https://app.getdemo.com.br/d/a73c0513-3d5a-4596-a58d-ab6582b685ce"
          frameborder="0"
          allowfullscreen
          style={{ width: '100%', height: '100%' }}
        ></iframe>
      </Modal>
    </header>
  );
};

export default Header;
