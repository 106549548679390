import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';
import Popup from '../utils/popup';
import isEqual from 'lodash/isEqual';

const UpdateMeeting = () => {
  const context = useContext(Context.Context);
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [nameBot, setNameBot] = useState('');
  const [meetingAt, setMeetingAt] = useState('');
  const [urlLink, setUrlLink] = useState('');
  const [template, setTemplate] = useState('');
  const [errors, setErrors] = useState([]);
  const authUser = context.authenticatedUser;
  const { id } = useParams();
  let navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [initialMeeting, setInitialMeeting] = useState({});
  const [meeting, setMeeting] = useState({
    title,
    company,
    customerName,
    nameBot,
    meetingAt,
    urlLink,
    templateId: template,
    userId: authUser.id
  });
  const [successPopup, setSuccessPopup] = useState(false);

  //Verifica mudanças armazenando os valores antes de atualizar
  useEffect(() => {
    context.data.getMeeting(authUser.emailAddress, authUser.password, id).then(response => {
      setInitialMeeting(response);
      setMeeting(response);
    });
  }, [id]);
  const hasChanges = useCallback(() => {
    return !isEqual(initialMeeting, meeting);
  }, [initialMeeting, meeting]);

  useEffect(() => {
    context.data
      .getTemplates(authUser.emailAddress, authUser.password)
      .then(response => {
        setTemplates(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing template', error);
        navigate('/error');
      });
  }, [navigate, context.data, authUser.emailAddress, authUser.password]);

  useEffect(() => {
    const controller = new AbortController();
    context.data
      .getMeeting(authUser.emailAddress, authUser.password, id)
      .then(response => {
        if (response.error === "Sorry, we couldn't find the course you were looking for.") {
          navigate('/notfound');
        } else {
          // If the currently authenticated user is the same as the Course author
          // Allow the user to update the Course
          if (authUser.id === response.User.id) {
            setTitle(response.title);
            setCompany(response.company);
            setCustomerName(response.customerName);
            setNameBot(response.nameBot);
            setMeetingAt(response.meetingAt);
            setUrlLink(response.urlLink);
            setTemplate(response.templateId);
          } else {
            navigate('/forbidden');
          }
        }
      })
      .catch(error => {
        console.error('Error fetching and parsing data', error);
        navigate('/error');
      })
      .finally(() => {
        setIsLoading(false);
      });
    // Clean up to prevent memory leak
    return () => controller?.abort();
  }, [authUser.id, id, navigate, context.data]);

  const onChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'title') {
      setTitle(value);
    }

    if (name === 'company') {
      setCompany(value);
    }

    if (name === 'customerName') {
      setCustomerName(value);
    }

    if (name === 'nameBot') {
      setNameBot(value);
    }

    if (name === 'meetingAt') {
      setMeetingAt(value);
    }

    if (name === 'urlLink') {
      setUrlLink(value);
    }

    if (name === 'template') {
      setTemplate(value);
    }
  };

  const submit = event => {
    event.preventDefault();
    //Course object to update the meeting
    if (hasChanges) {
      setSuccessPopup(true);
    }

    // Obtenha o templateId atual da reunião para comparação
    const currentTemplateId = initialMeeting.templateId;
    const meeting = {
      title,
      company,
      customerName,
      nameBot,
      meetingAt,
      urlLink,
      templateId: template,
      userId: authUser.id
    };

    context.data
      .updateMeeting(id, meeting, authUser.emailAddress, authUser.password)
      .then(response => {
        if (response.length) {
          setErrors(response);
        } else {
          //Verifique se o templateId foi alterado
          if (currentTemplateId !== template) {
            //Se troca de template, requisição para loadTemplate gerar respostas, console log de teste abaixo:
            //console.log('Gerando respostas para reunião de id: ', id, '\nTemplate de id: ', template, )
            context.data
              .loadTemplate(authUser.emailAddress, authUser.password, id)
              .then(() => {
                //Redireciona após carregar o novo template
                navigate('/');
              })
              .catch(error => {
                console.error(error);
                navigate('/error');
              });
          } else {
            //Se o templateId não mudou, apenas redireciona normalmente
            navigate('/');
          }
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };

  const cancel = event => {
    event.preventDefault();
    navigate('/');
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="wrap">
      <h2>Altera Reunião</h2>
      {errors.length ? (
        <div className="validation--errors">
          <h3>Validation Errors</h3>
          <ul>
            {errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      ) : null}
      <form>
        <div className="main--flex">
          <div>
            <label htmlFor="title">Nome da reunião</label>
            <input id="title" name="title" type="text" value={title} onChange={onChange} />

            <label htmlFor="company">Empresa</label>
            <input id="company" name="company" value={company} onChange={onChange} />

            <label htmlFor="meetingAt">Data e hora</label>
            <input id="meetingAt" name="meetingAt" value={meetingAt} onChange={onChange} />
          </div>
          <div>
            <label htmlFor="customerName">Contato</label>
            <input id="customerName" name="customerName" value={customerName} onChange={onChange} />

            <label htmlFor="urlLink">Link da Reunião</label>
            <input id="urlLink" name="urlLink" value={urlLink} onChange={onChange}></input>

            <label htmlFor="nameBot">Nome do Assistente</label>
            <input id="nameBot" name="nameBot" value={nameBot} onChange={onChange} />

            <label htmlFor="template">Template</label>
            <select id="template" name="template" value={template} onChange={onChange}>
              <option value="">Escolha o Template</option>
              {templates.map(template => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button className="button" type="submit" onClick={submit}>
          Atualizar
        </button>
        <button className="button button-secondary" onClick={cancel}>
          Cancelar
        </button>
      </form>
      {successPopup && (
        <Popup
          message="Processando dados..."
          isLoading={true}
          onClose={() => setSuccessPopup(false)}
        />
      )}
    </div>
  );
};

export default UpdateMeeting;
