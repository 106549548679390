import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';
import { Input, Button, message, Space, Switch, Select, Checkbox } from 'antd';
import googleagenda from './google-agenda.png';
import microsoftoutlook from './microsoft_office_outlook.png';
import IntegrationsV2 from './IntegrationsV2';

const Integrations = () => {
  const context = useContext(Context.Context);
  const [isLoading] = useState(false);
  const authUser = context.authenticatedUser;
  const [botName, setBotName] = useState(authUser.botName);
  const [messageApi, contextHolder] = message.useMessage();
  const [botao, setBotao] = useState(3);
  const [importAgenda, setImportAgenda] = useState(authUser.importMeeting);
  const [sendEmail, setSendEmail] = useState(authUser.emailMeeting);
  const [defaultTemplate, setDefaultTemplate] = useState(authUser.isDefaultTemplate);
  const [template, setTemplate] = useState(authUser.templateId);
  const [templates, setTemplates] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    context.data
      .getTemplates(authUser.emailAddress, authUser.password)
      .then(response => {
        setTemplates(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing template', error);
        navigate('/error');
      });
  }, [navigate, context.data, authUser.emailAddress, authUser.password]);

  // Função para gerar um código de verificação aleatório
  function generateCodeVerifier() {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  // Função para gerar um desafio de código baseado no código de verificação
  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode.apply(null, new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  function authCalendar() {
    // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&response_type=code&client_id=620388996179-g39l89j84lg1eh04ko2lvink4eikftpe.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapp.salesbud.site%2Fmeetings%2Fauthcalendar';
    window.location.href =
      'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&prompt=consent&response_type=code&client_id=413621096721-n4khblaeqlalcemnvtr4nqnfdfu6u04d.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapp.salesbud.site%2Fmeetings%2Fauthcalendar';
    //window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&prompt=consent&response_type=code&client_id=413621096721-n4khblaeqlalcemnvtr4nqnfdfu6u04d.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost:3000%2Fmeetings%2Fauthcalendar';
  }

  async function authCalendarOutlook() {
    //navigate('/meetings/authcalendaroutlook');
    const clientId = 'daa85404-8414-4892-a2fb-4261645f3a89';
    const redirectUri = 'https://app.salesbud.site/meetings/authcalendaroutlook';
    const authority = 'https://login.microsoftonline.com/common';

    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const state = Math.random().toString(36).substring(7); // Gerar um estado aleatório para proteção CSRF

    const authUrl = `${authority}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&response_mode=query&scope=User.Read%20Calendars.Read%20offline_access&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    await context.data.setCalendarOutlook(
      authUser.emailAddress,
      authUser.password,
      codeVerifier,
      state
    );
    window.location.href = authUrl;
    //window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=daa85404-8414-4892-a2fb-4261645f3a89&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fmeetings%2Fauthcalendaroutlook&response_mode=query&scope=User.Read%20Calendars.Read&state=12345';
  }

  function syncCalendar() {
    navigate('/meetings/synccalendar');
  }
  function syncCalendarOutlook() {
    navigate('/meetings/synccalendaroutlook');
  }
  function onChangeBotName(value) {
    setBotName(value);
  }

  function onClickBotName() {
    authUser.botName = botName;
    authUser.importMeeting = importAgenda;
    authUser.emailMeeting = sendEmail;
    authUser.templateId = template;
    authUser.isDefaultTemplate = defaultTemplate;
    context.data
      .setBotName(
        botName,
        authUser.emailAddress,
        authUser.password,
        importAgenda,
        sendEmail,
        template,
        defaultTemplate
      )
      .then(errors => {
        if (errors.length) {
          messageApi.open({
            type: 'error',
            content: errors
          });
        } else {
          messageApi.open({
            type: 'success',
            content: 'Assistente Atualizado!'
          });
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  }

  const onChangeSwitch = value => checked => {
    if (checked) setImportAgenda(value);
    else setImportAgenda(0);
  };
  const onChangeSwitchEmail = value => checked => {
    if (checked) setSendEmail(value);
    else setSendEmail(0);
  };
  const handleChange = value => {
    //console.log(`selected ${value}`);
    setTemplate(value);
  };
  const onChange = e => {
    //console.log(`checked = ${e.target.checked}`);
    setDefaultTemplate(e.target.checked);
  };

  const options = templates.map(template => ({
    label: template.name,
    value: template.id
  }));

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="wrap main--grid">
        <Link
          to="#"
          className={`course--module course--link ${botao === 3 ? 'active' : ''}`}
          onClick={e => {
            e.preventDefault();
            setBotao(3);
          }}
        >
          <h2 className="course--label">Configuração do BOT</h2>
        </Link>
        <Link
          to="#"
          className={`course--module course--link ${botao === 1 ? 'active' : ''}`}
          onClick={e => {
            e.preventDefault();
            setBotao(1);
          }}
        >
          <h2 className="course--label">Integração de agenda</h2>
        </Link>
        <Link
          to="#"
          className={`course--module course--link ${botao === 2 ? 'active' : ''}`}
          onClick={e => {
            e.preventDefault();
            setBotao(2);
          }}
        >
          <h2 className="course--label">Integração de CRMs</h2>
        </Link>
      </div>
      <br />
      {botao === 2 ? (
        <IntegrationsV2 />
      ) : botao === 1 ? (
        <div className="wrap">
          <div className="wrap" style={{ margin: '0', width: '500px' }}>
            <span
              className="course--module course--add--module"
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <img
                src={googleagenda}
                alt="Google Agenda"
                style={{ width: '70px', height: 'auto', marginRight: '20px' }}
              />
              {!authUser.authCalendar ? (
                <Button style={{ marginLeft: 'auto' }} onClick={authCalendar}>
                  Conectar ao Google Agenda
                </Button>
              ) : (
                <Button style={{ marginLeft: 'auto' }} onClick={syncCalendar}>
                  Importar Google Agenda
                </Button>
              )}
            </span>
            <span
              className="course--module course--add--module"
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <img
                src={microsoftoutlook}
                alt="Google Agenda"
                style={{ width: '70px', height: 'auto', marginRight: '20px' }}
              />
              {!authUser.authOutlook ? (
                <Button style={{ marginLeft: 'auto' }} onClick={authCalendarOutlook}>
                  Conectar ao Microsoft Outlook
                </Button>
              ) : (
                <Button style={{ marginLeft: 'auto' }} onClick={syncCalendarOutlook}>
                  Importar Agenda do Outlook
                </Button>
              )}
            </span>
          </div>
        </div>
      ) : (
        <div className="wrap">
          <Space>
            Nome do Assistente:
            <Input
              className="form--centered"
              id="botName"
              type="text"
              value={botName}
              size="large"
              style={{ width: '300px' }}
              onChange={e => onChangeBotName(e.target.value)}
            />
          </Space>
          <br />
          <br />
          <Space>
            Template utilizado na integração da Agenda
            <Select
              defaultValue={template}
              style={{
                width: 320
              }}
              onChange={handleChange}
              options={options}
            />
            {contextHolder}
          </Space>
          <br />
          {authUser.isAdmin && (
            <Checkbox checked={defaultTemplate} onChange={onChange}>
              Utilizar template acima para todo o time
            </Checkbox>
          )}
          <br />
          <br />
          Importar Agenda
          <br />
          <br />
          <Space>
            <Switch checked={importAgenda === 1} onChange={onChangeSwitch(1)} />
            Todas reuniões com link
          </Space>
          <br />
          <br />
          <Space>
            <Switch checked={importAgenda === 2} onChange={onChangeSwitch(2)} />
            Somente reuniões que eu organizo
          </Space>
          <br />
          <br />
          <Space>
            <Switch checked={importAgenda === 3} onChange={onChangeSwitch(3)} />
            Somente reuniões internas
          </Space>
          <br />
          <br />
          <Space>
            <Switch checked={importAgenda === 4} onChange={onChangeSwitch(4)} />
            Somente reuniões externas
          </Space>
          <br />
          <br />
          Enviar resumo da reunião
          <br />
          <br />
          <Space>
            <Switch checked={sendEmail === 1} onChange={onChangeSwitchEmail(1)} />
            Somente para o meu e-mail
          </Space>
          <br />
          <br />
          <Space>
            <Switch checked={sendEmail === 2} onChange={onChangeSwitchEmail(2)} />
            Para todos os participantes
          </Space>
          <br />
          <br />
          <Space>
            <Button type="primary" onClick={() => onClickBotName()}>
              Salvar todas as configurações
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};

export default Integrations;
