import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledLoadingButton = styled(LoadingButton)({
  backgroundColor: '#370144',
  color: '#fff',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#6b4f8b'
  },
  '&.MuiLoadingButton-loading': {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: `1px solid #370144`
  }
});

const CustomLoadingButton = props => {
  return <StyledLoadingButton {...props} />;
};

export default CustomLoadingButton;
