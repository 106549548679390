import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';
import moment from 'moment';
import { Input, Radio, Select, Space, Modal } from 'antd';

const Meetings = () => {
  const context = useContext(Context.Context);
  const [data, setData] = useState([]);
  const [usersCompany, setUsersCompany] = useState([]);
  const [filter, setFilter] = useState('');
  const [position, setPosition] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const authUser = context.authenticatedUser;
  const [modalAberto, setModalAberto] = useState(false);

  const handleModalClose = () => {
    setModalAberto(false);
  };

  const handleModalOpen = () => {
    setModalAberto(true);
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('firstVisit') === null;
    if (isFirstVisit) {
      handleModalOpen();
      localStorage.setItem('firstVisit', 'false');
    }
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    context.data
      .getMeetings(authUser.emailAddress, authUser.password)
      .then(response => {
        setData(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing data', error);
        navigate('/error');
      });
    context.data
      .getUsersCompany(authUser.emailAddress, authUser.password)
      .then(response => {
        setUsersCompany(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing data', error);
        navigate('/error');
      })
      .finally(() => setIsLoading(false));
  }, [navigate, context.data, authUser.emailAddress, authUser.password]);

  const filteredMeetings = useMemo(() => {
    return data
      .slice()
      .sort((a, b) => new Date(b.meetingAt) - new Date(a.meetingAt))
      .filter(meeting => {
        return (
          position === 'all' ||
          (position === 'disable' && meeting.status === 0) ||
          (position === 'tobe' && (meeting.status === 1 || meeting.status === 2)) ||
          (position === 'done' && meeting.status === 3) ||
          (position === 'noshow' && meeting.status === 4)
        );
      })
      .filter(meeting => {
        return (
          filter === '' ||
          meeting.title.toLowerCase().includes(filter.toLowerCase()) ||
          meeting.company.toLowerCase().includes(filter.toLowerCase()) ||
          meeting.customerName.toLowerCase().includes(filter.toLowerCase()) ||
          moment(meeting.meetingAt).format('DD/MM/YYYY HH:mm').includes(filter.toLowerCase())
        );
      })
      .map(meeting => {
        let linkClassName;
        if (meeting.status === 0) {
          linkClassName = 'course--module disable-meeting';
        } else if (meeting.status === 1) {
          linkClassName = 'course--module upcoming-meeting';
        } else if (meeting.status === 2) {
          linkClassName = 'course--module ongoing-meeting';
        } else if (meeting.status === 4) {
          linkClassName = 'course--module noshow-meeting';
        } else {
          linkClassName = 'course--module completed-meeting';
        }

        return (
          <Link to={`/meetings/${meeting.id}`} className={linkClassName} key={meeting.id}>
            <h2 className="course--label">
              Reunião (
              {meeting.status === 0
                ? 'Desativada'
                : meeting.status === 1
                  ? 'Em breve'
                  : meeting.status === 2
                    ? 'Em andamento'
                    : meeting.status === 4
                      ? 'Não aconteceu'
                      : 'Concluída'}
              )
            </h2>
            <h2 className="course--label">
              {moment(meeting.meetingAt).format('DD/MM/YYYY HH:mm')}
            </h2>
            <h3 className="course--title">
              {meeting.title.length > 50 ? `${meeting.title.substring(0, 50)}...` : meeting.title}
            </h3>
            <h2 className="course--label">{meeting.company}</h2>
          </Link>
        );
      });
  }, [data, filter, position]);

  const onChangeSelect = value => {
    if (value)
      context.data
        .getMeetingsUser(authUser.emailAddress, authUser.password, value)
        .then(response => {
          setData(response);
        })
        .catch(error => {
          console.error('Error fetching and parsing data', error);
          navigate('/error');
        });
    else
      context.data
        .getMeetings(authUser.emailAddress, authUser.password)
        .then(response => {
          setData(response);
        })
        .catch(error => {
          console.error('Error fetching and parsing data', error);
          navigate('/error');
        });
    console.log(`selected ${value}`);
  };

  const onSearchSelect = value => {
    console.log('search:', value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const FilterInput = useCallback(({ value, onChange, position, setPosition, usersCompany }) => {
    return (
      <div style={{ padding: '20px' }}>
        <Space>
          <Space>
            <Radio.Group value={position} onChange={e => setPosition(e.target.value)}>
              <Radio.Button value="all">Todas</Radio.Button>
              <Radio.Button value="tobe">Em breve</Radio.Button>
              <Radio.Button value="done">Concluídas</Radio.Button>
              <Radio.Button value="noshow">Não aconteceu</Radio.Button>
              <Radio.Button value="disable">Desativadas</Radio.Button>
            </Radio.Group>
          </Space>
          <Input
            style={{ width: '380px' }}
            id="filterInput"
            type="text"
            allowClear
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder="Digite o filtro..."
          />
          <Space></Space>
          <Space></Space>
          {usersCompany && usersCompany.length > 0 ? (
            <Space>
              Usuário:
              <Select
                showSearch
                placeholder="Selecione um usuário"
                optionFilterProp="children"
                allowClear
                onChange={onChangeSelect}
                onSearch={onSearchSelect}
                filterOption={filterOption}
                options={usersCompany.map(user => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`
                }))}
              />
            </Space>
          ) : null}
        </Space>
      </div>
    );
  }, []);

  const handleFilterChange = filterValue => {
    setFilter(filterValue);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="wrap">
      <FilterInput
        value={filter}
        onChange={handleFilterChange}
        position={position}
        setPosition={setPosition}
        usersCompany={usersCompany}
      />
      <div className="wrap main--grid">
        <Modal
          title="Faça um TOUR"
          visible={modalAberto}
          onCancel={handleModalClose}
          footer={null}
          width="95%"
          style={{ top: 5 }}
          bodyStyle={{ height: '530px' }}
          centered={true}
        >
          <iframe
            title="Tour"
            src="https://app.getdemo.com.br/d/a73c0513-3d5a-4596-a58d-ab6582b685ce"
            frameborder="0"
            allowfullscreen
            style={{ width: '100%', height: '100%' }}
          ></iframe>
        </Modal>
        <Link to="/meetings/create" className="course--module course--add--module">
          <span className="course--add--title">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 13 13"
              className="add"
            >
              <polygon points="7,6 7,0 6,0 6,6 0,6 0,7 6,7 6,13 7,13 7,7 13,7 13,6 "></polygon>
            </svg>
            Nova Reunião
          </span>
        </Link>
        {filteredMeetings}
      </div>
    </div>
  );
};

export default Meetings;
