import React, { useState, useContext, useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNavigate } from 'react-router-dom';
import Context from '../../Context';
moment.locale('pt-br');
const CreateMeeting = () => {
  const context = useContext(Context.Context);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [company, setCompany] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [urlLink, setUrlLink] = useState('');
  const [template, setTemplate] = useState('');
  const [errors, setErrors] = useState([]);
  const authUser = context.authenticatedUser;
  const [meetingAt, setMeetingAt] = useState(moment());
  const [nameBot, setNameBot] = useState(authUser.botName);

  const onChangeMeetingAt = date => {
    setMeetingAt(date);
  };
  let navigate = useNavigate();

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    context.data
      .getTemplates(authUser.emailAddress, authUser.password)
      .then(response => {
        setTemplates(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing template', error);
        navigate('/error');
      });
  }, [navigate, context.data, authUser.emailAddress, authUser.password]);

  const onChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'meetingTitle') {
      setMeetingTitle(value);
    }

    if (name === 'company') {
      setCompany(value);
    }

    if (name === 'customerName') {
      setCustomerName(value);
    }

    if (name === 'nameBot') {
      setNameBot(value);
    }

    if (name === 'meetingAt') {
      setMeetingAt(value);
    }

    if (name === 'urlLink') {
      setUrlLink(value);
    }

    if (name === 'template') {
      setTemplate(value);
    }
  };

  const submit = event => {
    event.preventDefault();
    // Meeting object to create a meeting
    const meeting = {
      title: meetingTitle,
      company: company,
      customerName,
      nameBot,
      meetingAt,
      urlLink,
      status: 1,
      templateId: template,
      userId: authUser.id
    };
    context.data
      .createMeeting(meeting, authUser.emailAddress, authUser.password)
      .then(errors => {
        if (errors.length) {
          setErrors(errors);
        } else {
          //authUser.countMeeting =authUser.countMeeting + 1;
          navigate('/');
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };

  const cancel = event => {
    event.preventDefault();
    navigate('/');
  };

  return (
    <div className="wrap">
      <h2>Nova Reunião</h2>
      {errors.length ? (
        <div className="validation--errors">
          <h3>Validation Errors</h3>
          <ul>
            {errors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </div>
      ) : null}
      <form>
        <div className="main--flex">
          <div>
            <label htmlFor="meetingTitle">Nome da reunião</label>
            <input
              id="meetingTitle"
              name="meetingTitle"
              type="text"
              value={meetingTitle}
              onChange={onChange}
            />

            <label htmlFor="company">Empresa</label>
            <input id="company" name="company" value={company} onChange={onChange} />

            <label htmlFor="meetingAt">Data e Hora</label>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <DateTimePicker
                id="meetingAt"
                name="meetingAt"
                onChange={onChangeMeetingAt}
                value={meetingAt}
              />
            </LocalizationProvider>
          </div>
          <div>
            <label htmlFor="customerName">Contato</label>
            <input id="customerName" name="customerName" value={customerName} onChange={onChange} />

            <label htmlFor="urlLink">Link da Reunião</label>
            <input id="urlLink" name="urlLink" value={urlLink} onChange={onChange}></input>

            <label htmlFor="nameBot">Nome do Assistente</label>
            <input id="nameBot" name="nameBot" value={nameBot} onChange={onChange} />

            <label htmlFor="template">Template</label>
            <select id="template" name="template" value={template} onChange={onChange}>
              <option value="">Escolha o Template</option>
              {templates.map(template => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="button" type="submit" onClick={submit}>
          Criar Reunião
        </button>
        <button className="button button-secondary" onClick={cancel}>
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default CreateMeeting;
