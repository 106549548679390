import { useMemo, useState, useContext } from 'react';
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable
} from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
//import { fakeData } from './makeData.ts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Context from '../../Context';

const Example = () => {
  const context = useContext(Context.Context);
  const authUser = context.authenticatedUser;
  const [validationErrors, setValidationErrors] = useState({});
  const columns = useMemo(
    () => [
      {
        accessorKey: 'question',
        header: 'Questão',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.question,
          helperText: validationErrors?.question,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              question: undefined
            })
          //optionally add validation checking for onBlur or onChange
        }
      }
    ],
    [validationErrors]
  );

  //call CREATE hook
  const { mutateAsync: createQuestion, isPending: isCreatingQuestion } = useCreateQuestion(
    context,
    authUser
  );
  //call READ hook
  const {
    data: fetchedQuestions = [],
    isError: isLoadingQuestionsError,
    isFetching: isFetchingQuestions,
    isLoading: isLoadingQuestions
  } = useGetQuestions(context, authUser);
  //call UPDATE hook
  const { mutateAsync: updateQuestion, isPending: isUpdatingQuestion } = useUpdateQuestion(
    context,
    authUser
  );
  //call DELETE hook
  const { mutateAsync: deleteQuestion, isPending: isDeletingQuestion } = useDeleteQuestion(
    context,
    authUser
  );

  //CREATE action
  const handleCreateQuestion = async ({ values, table }) => {
    const newValidationErrors = validateQuestion(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createQuestion(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveQuestion = async ({ row, values, table }) => {
    const newValidationErrors = validateQuestion(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    values.id = row.original.id;
    await updateQuestion(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = row => {
    if (window.confirm('Certeza que quer excluir essa questão?')) {
      deleteQuestion(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    initialState: { density: 'compact' },
    enableDensityToggle: false,
    columns,
    data: fetchedQuestions,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: row => row.id,
    muiToolbarAlertBannerProps: isLoadingQuestionsError
      ? {
          color: 'error',
          children: 'Error loading data'
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px'
      }
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateQuestion,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveQuestion,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Novo Item
      </Button>
    ),
    state: {
      isLoading: isLoadingQuestions,
      isSaving: isCreatingQuestion || isUpdatingQuestion || isDeletingQuestion,
      showAlertBanner: isLoadingQuestionsError,
      showProgressBars: isFetchingQuestions
    }
  });
  return <MaterialReactTable table={table} />;
};

//CREATE hook (post new user to api)
function useCreateQuestion(context, authUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async question => {
      //send api update request here
      question.templateId = context.templateId;
      const newQuestionInfo = await context.data.createTemplateQuestions(
        question,
        authUser.emailAddress,
        authUser.password
      );
      // Retornar os dados da nova pergunta, que provavelmente incluirá o novo ID
      return newQuestionInfo;
    },
    /*
    //client side optimistic update
    onMutate: (newQuestionInfo) => {
      queryClient.setQueryData(['questions'], (prevQuestions) => [
        ...prevQuestions,
        {
          ...newQuestionInfo,
          id: (Math.random() + 1).toString(36).substring(7),
        },
      ]);
    }, */
    // Atualizar a consulta com os dados da nova pergunta, incluindo o novo ID
    onSuccess: data => {
      queryClient.setQueryData(['questions'], prevQuestions => [
        ...prevQuestions,
        data // Adicionar a nova pergunta com o novo ID aos dados da consulta
      ]);
    }
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetQuestions(context, authUser) {
  return useQuery({
    queryKey: ['questions'],
    queryFn: async () => {
      return context.data.getTemplateQuestions(
        context.templateId,
        authUser.emailAddress,
        authUser.password
      );
    },
    refetchOnWindowFocus: false
  });
}

//UPDATE hook (put user in api)
function useUpdateQuestion(context, authUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async question => {
      await context.data.updateTemplateQuestions(
        question.id,
        question,
        authUser.emailAddress,
        authUser.password
      );
    },
    //client side optimistic update
    onMutate: newQuestionInfo => {
      queryClient.setQueryData(['questions'], prevQuestions =>
        prevQuestions?.map(prevQuestion =>
          prevQuestion.id === newQuestionInfo.id ? newQuestionInfo : prevQuestion
        )
      );
    }
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteQuestion(context, authUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async questionId => {
      await context.data.deleteTemplateQuestions(
        questionId,
        authUser.emailAddress,
        authUser.password
      );
      //await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      //return Promise.resolve();
    },
    //client side optimistic update
    onMutate: questionId => {
      queryClient.setQueryData(['questions'], prevQuestions =>
        prevQuestions?.filter(question => question.id !== questionId)
      );
    }
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default ExampleWithProviders;

const validateRequired = value => !!value.length;

function validateQuestion(question) {
  return {
    question: !validateRequired(question.question) ? 'Preecher o item' : ''
  };
}
