import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Header from './Components/Header';
import Courses from './Components/Courses/Courses';
import CourseDetail from './Components/Courses/CourseDetail';
import UpdateCourse from './Components/Courses/UpdateCourse';
import AuthCalendar from './Components/Meetings/AuthCalendar';
import AuthCalendarOutlook from './Components/Meetings/AuthCalendarOutlook';
import SyncCalendar from './Components/Meetings/SyncCalendar';
import SyncCalendarOutlook from './Components/Meetings/SyncCalendarOutlook';
import Meetings from './Components/Meetings/Meetings';
import MeetingDetail from './Components/Meetings/MeetingDetail';
import UpdateMeeting from './Components/Meetings/UpdateMeeting';
import CreateMeeting from './Components/Meetings/CreateMeeting';
import UserSignIn from './Components/User/UserSignIn';
import UserSignUp from './Components/User/UserSignUp';
import UserSignOut from './Components/User/UserSignOut';
import CreateCourse from './Components/Courses/CreateCourse';
import NotFound from './Components/Errors/NotFound';
import Forbidden from './Components/Errors/Forbidden';
import UnhandledError from './Components/Errors/UnhandledError';
import Templates from './Components/Templates/Templates';
import TemplateDetail from './Components/Templates/TemplateDetail';
import UpdateTemplate from './Components/Templates/UpdateTemplate';
import CreateTemplate from './Components/Templates/CreateTemplate';
import Integrations from './Components/Integrations/Integrations';
import Enablement from './Components/Meetings/Enablement';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <div id="root">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Navigate replace to="/meetings" />} />

          <Route path="/meetings/authcalendar" element={<PrivateRoute />}>
            <Route path="/meetings/authcalendar" element={<AuthCalendar />} />
          </Route>
          <Route path="/meetings/authcalendaroutlook" element={<PrivateRoute />}>
            <Route path="/meetings/authcalendaroutlook" element={<AuthCalendarOutlook />} />
          </Route>
          <Route path="/meetings/synccalendar" element={<PrivateRoute />}>
            <Route path="/meetings/synccalendar" element={<SyncCalendar />} />
          </Route>
          <Route path="/meetings/synccalendaroutlook" element={<PrivateRoute />}>
            <Route path="/meetings/synccalendaroutlook" element={<SyncCalendarOutlook />} />
          </Route>
          <Route path="/meetings" element={<PrivateRoute />}>
            <Route path="/meetings" element={<Meetings />} />
          </Route>
          <Route path="/meetings/:id" element={<PrivateRoute />}>
            <Route path="/meetings/:id" element={<MeetingDetail />} />
          </Route>
          <Route path="/meetings/create" element={<PrivateRoute />}>
            <Route path="/meetings/create" element={<CreateMeeting />} />
          </Route>
          <Route path="/meetings/:id/update" element={<PrivateRoute />}>
            <Route path="/meetings/:id/update" element={<UpdateMeeting />} />
          </Route>
          <Route path="/integrating" element={<PrivateRoute />}>
            <Route path="/integrating" element={<Integrations />} />
          </Route>
          <Route path="/meetings/enablement" element={<PrivateRoute />}>
            <Route path="/meetings/enablement" element={<Enablement />} />
          </Route>
          <Route path="/templates" element={<PrivateRoute />}>
            <Route path="/templates" element={<Templates />} />
          </Route>
          <Route path="/templates/:id" element={<PrivateRoute />}>
            <Route path="/templates/:id" element={<TemplateDetail />} />
          </Route>
          <Route path="/templates/create" element={<PrivateRoute />}>
            <Route path="/templates/create" element={<CreateTemplate />} />
          </Route>
          <Route path="/templates/:id/update" element={<PrivateRoute />}>
            <Route path="/templates/:id/update" element={<UpdateTemplate />} />
          </Route>
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:id" element={<CourseDetail />} />
          <Route path="/courses/:id/update" element={<PrivateRoute />}>
            <Route path="/courses/:id/update" element={<UpdateCourse />} />
          </Route>
          <Route path="/signin" element={<UserSignIn />} />
          {/* <Route path="/signup" element={<UserSignUp />} /> */}
          <Route path="/signout" element={<UserSignOut />} />
          <Route path="/courses/create" element={<PrivateRoute />}>
            <Route path="/courses/create" element={<CreateCourse />} />
          </Route>
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/error" element={<UnhandledError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
