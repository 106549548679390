import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';

const SyncCalendar = () => {
  const context = useContext(Context.Context);
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const authUser = context.authenticatedUser;
  let navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    context.data
      .getSyncCalendar(authUser.emailAddress, authUser.password)
      .then(response => {
        setData('');
      })
      .catch(error => {
        console.error('Error fetching and parsing data', error);
        //navigate('/error');
      })
      .finally(() => {
        setIsLoading(false);
        navigate('/meetings');
      });
  }, [navigate, context.data, authUser.emailAddress, authUser.password]);

  return isLoading ? <Loading /> : <div>{data}</div>;
};

export default SyncCalendar;
