import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Context from '../../Context';
import Loading from '../Loading';
import { Input, Button, Modal, List, Spin } from 'antd';
import { SendOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
const MeetingDetail = () => {
  const { Search } = Input;
  const context = useContext(Context.Context);
  let meetingDetail = useState('');
  const [meeting, setMeetingDetail] = useState({});
  const [meetinganswers, setMeetingAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isTranscription, setIsTranscription] = useState(false);
  const authUser = context.authenticatedUser;
  const [videoUrl, setVideoUrl] = useState('');
  const [response, setResponse] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateQuestion, setTemplateQuestions] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    //Fetch a meeting from the database
    const controller = new AbortController();

    context.data
      .getMeeting(authUser.emailAddress, authUser.password, id)
      .then(response => {
        if (response.id) {
          setMeetingDetail(response);

          //Fetch meeting answers only if meeting exists
          context.data
            .getMeetingAnswers(response.id)
            .then(response2 => {
              setMeetingAnswers(response2);
            })
            .catch(error => {
              console.error('Error fetching and parsing meeting answers', error);
              navigate('/error');
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          //If there is no meeting ID, direct to Not Found
          navigate('/notfound');
        }
      })
      .catch(error => {
        console.error('Error fetching and parsing meeting', error);
        navigate('/error');
      });

    //Clean up to prevent memory leak
    return () => controller?.abort();
  }, [id, navigate, context.data]);

  const handleModalOpenVideo = () => {
    const fetchVideo = async () => {
      const botId = meeting.idBot;
      try {
        const data = await context.data.getVideoUrl(botId);
        // Supondo que a resposta da API tenha uma propriedade 'videoUrl' que contém o URL do vídeo
        setVideoUrl(data.video_url);
      } catch (error) {
        console.error('Erro:', error);
        // Lidar com erros, exibir mensagem de erro na UI, etc.
      }
    };

    fetchVideo();
    setIsVideo(true);
    setIsTranscription(false);
    setIsModalOpen(true);
  };
  const handleModalOpenTranscription = () => {
    setIsVideo(false);
    setIsTranscription(true);
    setIsModalOpen(true);
  };
  const handleModalOpenSummary = () => {
    setIsVideo(false);
    setIsTranscription(false);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const copyMeeting = () => {
    // Obter o elemento da div que contém a transcrição
    const transcriptionElement = document.querySelector('.wrap .main--flex div ul');

    // Selecionar o conteúdo da div
    const range = document.createRange();
    range.selectNodeContents(transcriptionElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Copiar o texto formatado para a área de transferência
    try {
      const successful = document.execCommand('copy');
      const msg = successful
        ? 'Template copiado para a área de transferência.'
        : 'Falha ao copiar template.';
      alert(msg);
    } catch (err) {
      console.error('Erro ao copiar template:', err);
      alert('Erro ao copiar template. Por favor, tente novamente.');
    } finally {
      // Limpar a seleção após a cópia
      selection.removeAllRanges();
    }
  };
  const handleModalBackgroundClick = event => {
    if (event.target === event.currentTarget) {
      // Verifica se o clique foi no fundo escuro da modal
      setIsModalOpen(false);
    }
  };
  if (meeting.id) {
    meetingDetail = (
      <div className="wrap">
        <div className="main--flex">
          <div>
            <h3 className="course--detail--title">
              Reunião (
              {meeting.status === 1
                ? 'Em Breve'
                : meeting.status === 2
                  ? 'Em andamento'
                  : meeting.status === 3
                    ? 'Concluida'
                    : meeting.status === 4
                      ? 'Não aconteceu'
                      : 'Desativada'}
              )
            </h3>
            <h4 className="course--name">
              {meeting.title} <Button onClick={copyMeeting}>Copiar</Button>
            </h4>

            <h2>Template {meeting.Template.name}</h2>
            <ul>
              {meetinganswers &&
                meetinganswers.length > 0 &&
                meetinganswers.map(item => (
                  <li key={item.id}>
                    <h3>{item.TemplateQuestion.question}</h3>
                    <p>{item.answer}</p>
                  </li>
                ))}
            </ul>
          </div>
          <div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <button
                className="button button-secondary"
                style={{ maxWidth: '170px' }}
                onClick={handleModalOpenTranscription}
              >
                Transcrição
              </button>
              <button
                className="button button-secondary"
                style={{ maxWidth: '170px' }}
                onClick={showModal}
              >
                Chat
              </button>
              <button
                className="button button-secondary"
                style={{ maxWidth: '170px' }}
                onClick={handleModalOpenVideo}
              >
                Video
              </button>
            </div>
            <h3 className="course--detail--title">Data e Hora</h3>
            <p>{moment(meeting.meetingAt).format('LLLL')}</p>
            <h3 className="course--detail--title">Cliente</h3>
            <ul className="course--detail--list">
              <ReactMarkdown>{meeting.company}</ReactMarkdown>
            </ul>

            <h3 className="course--detail--title">Contato</h3>
            <ul className="course--detail--list">
              <ReactMarkdown>{meeting.customerName}</ReactMarkdown>
            </ul>

            <h3 className="course--detail--title">Assistente da Reunião</h3>
            <ul className="course--detail--list">
              <ReactMarkdown>{meeting.nameBot}</ReactMarkdown>
            </ul>

            <h3 className="course--detail--title">Link da reunião</h3>
            <ul className="course--detail--list">
              <ReactMarkdown>
                {meeting.urlLink.length > 40
                  ? `${meeting.urlLink.substring(0, 40)}...`
                  : meeting.urlLink}
              </ReactMarkdown>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  const handleDelete = event => {
    event.preventDefault();
    context.data
      .deleteMeeting(id, authUser.emailAddress, authUser.password)
      .then(response => {
        // If meeting deletion is successful, then there should be no response returned
        if (response.length) {
          navigate('/error');
        } else {
          navigate('/');
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };

  const handleDisableBot = event => {
    event.preventDefault();
    context.data
      .disableMeeting(id, authUser.emailAddress, authUser.password)
      .then(response => {
        // If meeting deletion is successful, then there should be no response returned
        if (response.length) {
          navigate('/error');
        } else {
          navigate('/');
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };

  const handleEnableBot = event => {
    event.preventDefault();
    context.data
      .enableMeeting(id, authUser.emailAddress, authUser.password)
      .then(response => {
        // If meeting deletion is successful, then there should be no response returned
        if (response.length) {
          navigate('/error');
        } else {
          navigate('/');
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };

  const copyTranscription = () => {
    // Obter o elemento da div que contém a transcrição
    const transcriptionElement = document.querySelector('.modallocal-content div');

    // Selecionar o conteúdo da div
    const range = document.createRange();
    range.selectNodeContents(transcriptionElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Copiar o texto formatado para a área de transferência
    try {
      const successful = document.execCommand('copy');
      const msg = successful
        ? 'Transcrição copiada para a área de transferência.'
        : 'Falha ao copiar transcrição.';
      alert(msg);
    } catch (err) {
      console.error('Erro ao copiar transcrição:', err);
      alert('Erro ao copiar transcrição. Por favor, tente novamente.');
    } finally {
      // Limpar a seleção após a cópia
      selection.removeAllRanges();
    }
  };

  const Prompt = ({ visible, onClose, meetingId }) => {
    const [inputValue, setInputValue] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
      if (visible) {
        inputRef.current.focus();
      }
    }, [visible]);

    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSend = async () => {
      if (inputValue.trim() === '') return;

      const newMessage = { text: inputValue, from: 'user' };
      setMessages([...messages, newMessage]);
      setInputValue('');
      setLoading(true);
      context.data
        .getMeetingsChat(authUser.emailAddress, authUser.password, meetingId, inputValue)
        .then(response => {
          setMessages(prevMessages => [...prevMessages, { text: response.result, from: 'bot' }]);
          setLoading(false);
        })
        .catch(error => {
          console.log('erro chat', error);
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Ocorreu um erro, tente novamente por favor.', from: 'bot' }
          ]);
          setLoading(false);
        });
    };

    return (
      <Modal
        title="Converse com o BUD!"
        visible={visible}
        onCancel={onClose}
        footer={null}
        width={800}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
          <div style={{ flex: 1, padding: '18px', overflowY: 'auto' }}>
            {messages.length > 0 && (
              <List
                dataSource={messages}
                renderItem={(message, index) => (
                  <List.Item
                    key={index}
                    style={{ textAlign: message.from === 'user' ? 'right' : 'left' }}
                  >
                    <List.Item.Meta
                      description={<div dangerouslySetInnerHTML={{ __html: message.text }} />}
                    />
                  </List.Item>
                )}
              />
            )}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{
              padding: '16px',
              borderTop: '1px solid #f0f0f0',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Input
              ref={inputRef}
              placeholder="Digite seu prompt..."
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onPressEnter={handleSend}
              suffix={
                loading ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                ) : (
                  <SendOutlined
                    onClick={handleSend}
                    style={{
                      cursor: 'pointer',
                      color:
                        inputValue.trim() === '' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)'
                    }}
                  />
                )
              }
            />
          </div>
        </div>
      </Modal>
    );
  };

  const ModalLocal = ({
    isTranscription,
    isVideo,
    isOpen,
    handleClose,
    text,
    handleBackgroundClick,
    copyTranscription,
    botId
  }) => {
    if (!isOpen) return null;

    /*
    const formatTextToHTML = (text) => {
      if (!text) {
        return '';
      }
      return text
        .split('\n')
        .filter(line => line.trim().length > 0)
        .map(line => {
            return `<li>${line.trim().substring(0)}</li>`;
        })
        .join('') + '</ul>';
    };
  */
    const onSearch = (value, meetingId) => {
      context.data
        .getMeetingsChat(authUser.emailAddress, authUser.password, meetingId, value)
        .then(response => {
          setResponse(response.result);
        })
        .catch(error => {
          console.error('Error fetching and parsing meeting answers', error);
          navigate('/error');
        });
    };

    const formattedText = text; // (isTranscription ? text : formatTextToHTML(text));
    return (
      <div className="modallocal" onClick={handleBackgroundClick}>
        <div className="modallocal-content" onClick={e => e.stopPropagation()}>
          {' '}
          {/* Evitar fechamento ao clicar no conteúdo */}
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          {isVideo ? (
            //<VideoPlayer botId={'8fafe75b-7a22-46cb-a9bc-34653b915548'} /> // Passa botId como prop para VideoPlayer
            <div>
              {meeting.title}
              {videoUrl && (
                <video controls>
                  <source src={videoUrl} type="video/mp4" />
                  {/* Inclua outras fontes de vídeo aqui, se necessário */}
                  Seu navegador não suporta o elemento de vídeo.
                </video>
              )}
            </div>
          ) : isTranscription ? (
            <>
              <h2>
                Transcrição da Reunião
                <Button onClick={copyTranscription}>Copiar Transcrição</Button>
              </h2>
              <div
                style={{ fontSize: '1.0em' }}
                dangerouslySetInnerHTML={{ __html: formattedText }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return isLoading ? (
    <Loading />
  ) : meeting ? (
    isModalOpen ? (
      isTranscription ? (
        <ModalLocal
          isTranscription={isTranscription}
          isVideo={isVideo}
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          text={meeting.transcription}
          handleBackgroundClick={handleModalBackgroundClick}
          copyTranscription={copyTranscription}
        />
      ) : isVideo ? (
        <ModalLocal
          isTranscription={isTranscription}
          isVideo={isVideo}
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          handleBackgroundClick={handleModalBackgroundClick}
        />
      ) : null
    ) : isModalVisible ? (
      <Prompt visible={isModalVisible} onClose={handleCloseModal} meetingId={meeting.id} />
    ) : (
      <div>
        <div className="actions--bar">
          <div className="wrap">
            {authUser && authUser.id === meeting.User.id ? (
              <Link to={`/meetings/${id}/update`} className="button">
                Alterar
              </Link>
            ) : null}
            {authUser && authUser.id === meeting.User.id ? (
              <button className="button" onClick={handleDelete}>
                Excluir
              </button>
            ) : null}
            {authUser && authUser.id === meeting.User.id && meeting.status === 0 ? (
              <button className="button" onClick={handleEnableBot}>
                Reativar BOT
              </button>
            ) : null}
            {authUser && authUser.id === meeting.User.id && meeting.status === 1 ? (
              <button className="button" onClick={handleDisableBot}>
                Remover BOT
              </button>
            ) : null}
            <Link to="/" className="button button-secondary">
              Volta para lista
            </Link>
          </div>
        </div>
        {meetingDetail}
      </div>
    )
  ) : null;
};

export default MeetingDetail;
