import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';
import Example from './Example';
import { message, Checkbox } from 'antd';

const TemplateDetail = () => {
  const context = useContext(Context.Context);
  const [template, setTemplateDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const authUser = context.authenticatedUser;
  const [isShared, setIsShared] = useState(false);

  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (context.templateId !== id) {
      context.actions.setTemplateId(id);
    }
  }, [id, context.templateId, context.actions]);

  useEffect(() => {
    // Fetch a meeting from the database
    const controller = new AbortController();
    context.data
      .getTemplate(id)
      .then(response => {
        if (response.id) {
          setTemplateDetail(response);
          const { isShared } = response;
          setIsShared(isShared);
        } else {
          // If there is no meeting ID, direct to Not Found
          navigate('/notfound');
        }
      })
      .catch(error => {
        console.error('Error fetching and parsing meeting', error);
        navigate('/error');
      })
      .finally(() => {
        setIsLoading(false);
      });
    // Clean up to prevent memory leak
    return () => controller?.abort();
  }, [id, navigate, context.data]);

  const handleDelete = event => {
    event.preventDefault();
    context.data
      .deleteTemplate(id, authUser.emailAddress, authUser.password)
      .then(response => {
        // If template deletion is successful, then there should be no response returned
        if (response.length) {
          messageApi.open({
            type: 'error',
            content: response
          });
        } else {
          navigate('/templates');
        }
      })
      .catch(error => {
        console.error(error);
        navigate('/error');
      });
  };
  const onChange = e => {
    //console.log(`checked = ${e.target.checked}`);
    setIsShared(e.target.checked);
    context.data.setIsSharedTemplate(
      id,
      e.target.checked,
      authUser.emailAddress,
      authUser.password
    );
  };
  return isLoading ? (
    <Loading />
  ) : template ? (
    <div>
      <div className="actions--bar">
        <div className="wrap">
          {contextHolder}
          {authUser && authUser.id === template.User.id ? (
            <button className="button" onClick={handleDelete}>
              Excluir
            </button>
          ) : null}
          <Link to="/templates" className="button button-secondary">
            Volta para lista
          </Link>
        </div>
      </div>
      <div className="wrap">
        <div className="main--flex">
          <div>
            <h4 className="course--name">{template.name}</h4>
            {authUser.isAdmin && (
              <Checkbox checked={isShared} onChange={onChange}>
                Compartilhar com o time
              </Checkbox>
            )}
          </div>
        </div>
        <Example />
      </div>
    </div>
  ) : null;
};

export default TemplateDetail;
